.profile-pic {
  position: relative;
  top: -42px; /* Ajusta este valor para superponer sobre la portada */
  z-index: 1; /* Asegura que esté encima de la imagen de portada */

}
.profile-pic-m {
  position: relative;
  top: -80px; /* Ajusta este valor para superponer sobre la portada */
  z-index: 1; /* Asegura que esté encima de la imagen de portada */
}
.profile-pic-l {
  position: relative;
  top: -90px; /* Ajusta este valor para superponer sobre la portada */
  z-index: 1; /* Asegura que esté encima de la imagen de portada */
}
.custom-button {
  min-width: 160px;
}

.cover-image {
  object-fit: cover; /* Mantener proporciones */
  width: 100%;
  height: 100%;
}
